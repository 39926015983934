import React from 'react'

// Components
import Wrapper from '../wrapper'
import Thumbnail from '../thumbnail'
import Seemore from '../seemore'

const _renderProjects = (projects) => {
  return projects.map((item, index) => {
    switch (index) {
      case 0:
        return (
          <div key={`preview-${index}`}
               className="thumbnail row__one col-sm-12 col-lg-5">
              <Thumbnail project={item} />
          </div>
        )
      case 1:
        return (
          <div key={`preview-${index}`}
               className="thumbnail row__one col-sm-12 col-lg-7">
              <Thumbnail project={item} />
          </div>
        )
      case 2:
      case 3:
        return (
          <div key={`preview-${index}`}
               className="thumbnail row__two col-sm-12 col-lg-6">
              <Thumbnail project={item} />
          </div>
        )
      case 4:
      case 5:
      case 6:
        return (
          <div key={`preview-${index}`}
               className="thumbnail row__three col-sm-12 col-lg-4">
              <Thumbnail project={item} />
          </div>
        )
      default:
        return null
    }
  })
}

const HomeProjects = ({ content, projects }) => (
  <Wrapper grid>
    <div id="home__projects" className="home__projects row">
      <div className="section
                      col-md-2
                      col-lg-3">
        <h2 className="section__title"
            dangerouslySetInnerHTML={{__html: content.section}} />
      </div>
      <div className="headline
                      col-md-10
                      col-lg-8">
        <h3 dangerouslySetInnerHTML={{__html: content.headline}} />
        <Seemore content={content} rotated />
      </div>
      <div className="home__projects__grid no-padding col-12">
        {_renderProjects(projects)}
      </div>
    </div>
  </Wrapper>
)

export default HomeProjects
