import React from 'react'
import { graphql } from 'gatsby'

// Components
import Layout from '../layouts'
import Seo from '../components/seo'
import Wrapper from '../components/wrapper'
import HomeHero from '../components/home/home_hero'
import HomeProjects from '../components/home/home_projects'
import HomeMethods from '../components/home/home_methods'
import HomeNext from '../components/home/home_next'

const _sortPreviewsBy = (previews) => {
  return previews.projects.sort((p1, p2) => p1.homeDisplayPosition - p2.homeDisplayPosition)
}

const IndexPage = ({ data, location, pageContext }) => {
  const { datoCmsHome, allDatoCmsSocial } = data

  return (
    <Layout path={location.pathname} locale={pageContext.locale} absolutePath={pageContext.absolutePath}>
      <Seo
        slug={''}
        content={datoCmsHome.seo}
        lang={pageContext.locale}
        publication={datoCmsHome.meta}
      />
      <React.Fragment>
        <Wrapper color="yellow">
          <HomeHero content={datoCmsHome}
                    socials={allDatoCmsSocial.edges} />
        </Wrapper>
        <Wrapper>
          <HomeProjects content={datoCmsHome.projects.find(el => el.model.apiKey === 'projects_content')}
                        projects={_sortPreviewsBy(datoCmsHome.projects.find(el => el.model.apiKey === 'preview'))}/>
        </Wrapper>
        <Wrapper color="black">
          <HomeMethods content={datoCmsHome.methods[0]} />
        </Wrapper>
        <Wrapper>
          <HomeNext subscription={datoCmsHome.subscription[0]}
                    next={datoCmsHome.nextSection} />
        </Wrapper>
      </React.Fragment>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery($locale: String!) {
    datoCmsHome(locale: {eq: $locale}) {
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      seo {
        title
        description
        image {
          url
          height
          width
        }
        twitterCard
      }
      disclaimer
      headline
      methods {
        headline
        paragraph
        textLink
        link {
          link
          locale
        }
        schematics {
          url
        }
      }
      subscription {
        headline
        textOptin
        fieldPlaceholder
        fieldCta
      }
      nextSection {
        title
        textLink
        link {
          link
          locale
        }
      }
      projects {
        ... on DatoCmsProjectsContent {
          id
          section
          headline
          textLink
          link {
            link
            locale
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsPreview {
          id
          model {
            apiKey
          }
          projects {
            headline
            clientName
            homeDisplayPosition
            thumbnail {
              alt
              gatsbyImageData(layout: CONSTRAINED)
            }
            slug
            locale
          }
        }
      }
    }
    allDatoCmsSocial(filter: {
      locale: { eq: $locale }
    }) {
      edges {
        node {
          id
          service
          copyName
          link
          icon {
            url
          }
        }
      }
    }
  }
`

