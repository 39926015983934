import React from 'react'
import { Scrollchor } from 'react-scrollchor';

// Components
import Wrapper from '../wrapper'

// Assets
import MnlArrow from '../../assets/images/MnlArrow'

const HomeHero = ({ content, socials }) => {
  return (
    <Wrapper grid>
      <div className="home__hero row no-gutters">
        <div className="disclaimer col-sm-11 col-md-8 offset-md-2 col-lg-8 offset-lg-1">
          <h2 dangerouslySetInnerHTML={{__html: content.disclaimer}} />
        </div>
        <div className="headline col-sm-12 col-md-11 col-lg-12">
          <h1 dangerouslySetInnerHTML={{__html: content.headline}} />
        </div>
        <div className="socials col-sm-11 col-md-11 col-lg-6">
          <ul>
            <li>
              <span className="dash long"></span>
            </li>

            {socials.map((item, index) => (
              // Social icons
              <li data-id={item.node.id}
                  key={`hero__socials-${index}`}>
                <a href={item.node.link}
                   target="_blank"
                   rel="noopener noreferrer"
                   title={item.node.copyName}>
                  <img src={item.node.icon.url} alt={item.node.service} />
                </a>
              </li>
            ))}

          </ul>
        </div>
        <div className="arrow">
          <Scrollchor animate={{offset: 20, duration: 400 }} to="#home__projects">
            <MnlArrow rotate={0} />
          </Scrollchor>
        </div>
      </div>
    </Wrapper>
  )
}

export default HomeHero
