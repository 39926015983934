import React from 'react'
import {Helmet} from "react-helmet";

// Components
import Wrapper from '../wrapper'
import Next from '../next'

export default class HomeNext extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      isSent: false,
      consent: false,
      email: '',
    }
  }

  render() {

    const { subscription, next } = this.props;

    return (
      <Wrapper grid>
        <div className="home__next row">
          <div className="subscription
                          col-sm-12
                          col-lg-6">
            <h3 dangerouslySetInnerHTML={{__html: subscription.headline}} />
            {this.state.isSent ? 
              <h2>Thanks for subscribing!</h2> : 
              <form
                ref={this.formRef}
                className="row no-gutters js-cm-form" 
                action="https://www.createsend.com/t/subscribeerror?description=" 
                method="post" 
                data-id="A61C50BEC994754B1D79C5819EC1255C3BFFB0501EDED3E288FC86E1F8AAA998EDB19D137DA07ACBCA9B9140C4FFE6C990F371698BE3AF53ED23D5A6957B99AD" 
                id="subForm"
              >
                  <div className="input__checkbox col-1">
                    <input 
                      type="checkbox" 
                      id="cm-privacy-consent" 
                      name="cm-privacy-consent" 
                      value={this.state.consent}
                      required
                      onChange={() => 
                        this.setState(prevState => ({
                          consent: !prevState.consent
                        }))
                      }
                    />
                    <span className="input__box"></span>
                  </div>
                  <div className="col-11">
                    <label htmlFor="cm-privacy-consent">
                      <span 
                        className="paragraph"
                        dangerouslySetInnerHTML={{__html: subscription.textOptin}} 
                      />
                    </label>
                  </div>
                <div className="input__submit
                                col-12">
                  <input 
                    type="email" 
                    name="cm-yhlrkyi-yhlrkyi"
                    className="js-cm-email-input"
                    id="fieldEmail"
                    value={this.state.email}
                    required
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                    placeholder={subscription.fieldPlaceholder}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                  <input 
                    id="cm-privacy-consent-hidden" 
                    name="cm-privacy-consent-hidden"
                    type="hidden" 
                    value="true" 
                  />
                  <input 
                    type="submit"
                    className="js-cm-submit-button"
                    value={subscription.fieldCta}
                  />
                </div>
                <Helmet>
                  <script type="text/javascript" src="https://js.createsend1.com/javascript/copypastesubscribeformlogic.js"></script>
                </Helmet>
              </form>
            }
          </div>
          <Next content={next} yellow />
        </div>
      </Wrapper>
    );
  }
}
