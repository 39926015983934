import React from 'react'

// Components
import Wrapper from '../wrapper'
import Seemore from '../seemore'

// Assets
import MnlMethods from '../../assets/images/MnlMethods'

const HomeMethods = ({ content }) => (
  <Wrapper grid>
    <div className="home__methods row no-padding">
      <div className="headline col-12 col-lg-6">
        <div>
          <h3 className="white"
              dangerouslySetInnerHTML={{__html: content.headline}} />
          <p className="paragraph white"
            dangerouslySetInnerHTML={{__html: content.paragraph}} />
          <Seemore content={content} rotated white />
        </div>
      </div>
      <div className="schematics col-12 col-lg-6">
        {/*<img src={content.schematics.url} alt={content.textLink} />*/}
        <MnlMethods />
      </div>
    </div>
  </Wrapper>
)

export default HomeMethods
