import React from "react";

const MnlMethods = props => (
  <svg {...props}>
    <g className="svg__wrapper" fill="none" fillRule="evenodd">
      <path
        className="dotted__lines"
        d="M161.185 81.51h240.246L79.275 402.645h318.18"
        stroke="#6E6E6E"
        strokeWidth={2}
        strokeDasharray={6}
      />
      <g className="steps" transform="translate(1 1)">
        <circle
          stroke="#FFF"
          strokeWidth={2}
          fill="#000"
          cx={80}
          cy={80}
          r={80}
        />
        <text
          className="steps__name"
          fontSize={28}
          fontWeight={600}
          fill="#FFF"
        >
          <tspan x={22.428} y={88}>
            discover
          </tspan>
        </text>
        <text
          className="steps__number"
          fontSize={20}
          fontStyle="condensed"
          fontWeight={300}
          fill="#FFF"
        >
          <tspan x={76} y={48}>
            1
          </tspan>
        </text>
      </g>
      <g className="steps" transform="translate(321 1)">
        <circle
          stroke="#FFF"
          strokeWidth={2}
          fill="#000"
          cx={80}
          cy={80}
          r={80}
        />
        <text
          className="steps__name"
          fontSize={28}
          fontWeight={600}
          fill="#FFF"
        >
          <tspan x={46.242} y={88}>
            think
          </tspan>
        </text>
        <text
          className="steps__number"
          fontSize={20}
          fontStyle="condensed"
          fontWeight={300}
          fill="#FFF"
        >
          <tspan x={76.5} y={48}>
            2
          </tspan>
        </text>
      </g>
      <g className="steps" transform="translate(161 161)">
        <circle
          stroke="#FFF"
          strokeWidth={2}
          fill="#000"
          cx={80}
          cy={80}
          r={80}
        />
        <text
          className="steps__name"
          fontSize={28}
          fontWeight={600}
          fill="#FFF"
        >
          <tspan x={36.358} y={88}>
            create
          </tspan>
        </text>
        <text
          className="steps__number"
          fontSize={20}
          fontStyle="condensed"
          fontWeight={300}
          fill="#FFF"
        >
          <tspan x={76.5} y={48}>
            3
          </tspan>
        </text>
      </g>
      <g className="steps" transform="translate(1 321)">
        <circle
          stroke="#FFF"
          strokeWidth={2}
          fill="#000"
          cx={80}
          cy={80}
          r={80}
        />
        <text
          className="steps__name"
          fontSize={28}
          fontWeight={600}
          fill="#FFF"
        >
          <tspan x={36.498} y={88}>
            design
          </tspan>
        </text>
        <text
          className="steps__number"
          fontSize={20}
          fontStyle="condensed"
          fontWeight={300}
          fill="#FFF"
        >
          <tspan x={76.5} y={48}>
            4
          </tspan>
        </text>
      </g>
      <g className="steps" transform="translate(321 321)">
        <circle
          stroke="#FFF"
          strokeWidth={2}
          fill="#000"
          cx={80}
          cy={80}
          r={80}
        />
        <text
          className="steps__name"
          fontSize={28}
          fontWeight={600}
          fill="#FFF"
        >
          <tspan x={33.586} y={88}>
            deliver
          </tspan>
        </text>
        <text
          className="steps__number"
          fontSize={20}
          fontStyle="condensed"
          fontWeight={300}
          fill="#FFF"
        >
          <tspan x={76.5} y={48}>
            5
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);

export default MnlMethods;
